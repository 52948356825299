import React from 'react';
import * as style from './mapUI.module.scss';
import { Transition, TransitionGroup } from 'react-transition-group';

const MapUIContainer = props => (
  <TransitionGroup className={style.mapUIContainer}>
    {!props.hidden && (
      <Transition timeout={750}>
        {state => (
          <div className={`${style.mapUIWrap} ${style['mapUIWrap-' + state]}`}>
            {props.children}
          </div>
        )}
      </Transition>
    )}
  </TransitionGroup>
);

export default MapUIContainer;
