import React, { Component } from 'react';
import * as style from './nukemapIntro.module.scss';
import LocationSelector from './locationSelector';
import VideoBackground from '../basic/videoBackground';
import Button from '../ui/button';
import translations from '../../utils/translations';

class NukemapIntro extends Component {
  constructor() {
    super();
    this.state = {
      isChoosing: false,
      showLocationButton: true,
      locating: false,
      rejected: false,
    };
    this.id = 'mapIntroLocation';
  }
  showLocationButton = () => {
    this.setState({ showLocationButton: true });
  };
  hideLocationButton = () => {
    this.setState({ showLocationButton: false });
  };

  handleFocus = () => {
    this.setState({ isChoosing: true });
  };
  handleBlur = () => {
    this.setState({ isChoosing: false });
    this.showLocationButton();
  };

  useMyLocation = () => {
    // console.log('check location allowed');
    if ('geolocation' in navigator) {
      // console.log('start locating');
      this.setState({ locating: true });
      navigator.geolocation.getCurrentPosition(
        position => {
          // reverse geocode location name
          // console.log('reverse geocode location name');
          this.props.mboxClient
            .geocodeReverse(
              {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
              {
                types: 'postcode',
                limit: 1,
              }
            )
            .then(res => {
              if (res.status === 200) {
                // console.log('updated location state');
                this.props.updateLocation({
                  name: res.entity.features[0].place_name,
                  long: position.coords.longitude,
                  lat: position.coords.latitude,
                });
                // console.log('got o map');
                this.props.handleSelect();
              }
            })
            .catch(err => {
              // handle errors
              console.error(translations.t('mapbox geocode call error'), err);
            });
        },
        error => {
          // console.log(error);
          if (error.code === 3) {
            alert(translations.t('Sorry, geolocation timed out. Please enter a location'));
          } else {
            alert(
              translations.t('Sorry, geolocation is not available. You must allow location services to use this feature.')
            );
          }

          this.setState({ locating: false, rejected: true });
        },
        {
          timeout: 15000, // 15 sec. timeout
          maximumAge: 60 * 1000 * 30, // 30 mins
        }
      );
    } else {
      /* geolocation IS NOT available */
      alert(translations.t('Sorry, geolocation is not available.'));
    }
  };

  render() {
    return (
      <div
        className={`${style.nukemapIntro} ${style[this.props.transitionState]}`}
      >
        <div
          ref={el => {
            this.wrapper = el;
          }}
          className={style.nukemapContent}
        >
          <div dangerouslySetInnerHTML={{ __html: this.props.introText }} />
          <div
            className={
              !this.state.locating ? style.inputWrap : style.inputWrapMask
            }
          >
            <div
              className={
                this.state.locating
                  ? style.locatingLabel
                  : style.locatingLabelHidden
              }
            >
              <span className={style.locatingInner}>{translations.t('Requesting Location')}</span>
            </div>
            <div
              className={
                this.state.locating
                  ? style.inputWrapHidden
                  : style.inputWrapDisplay
              }
            >
              <LocationSelector
                id={this.id}
                onEmptyValue={this.showLocationButton}
                onAutofill={this.hideLocationButton}
                updateLocation={this.props.updateLocation}
                color="clear"
                handleFocus={this.handleFocus}
                handleBlur={this.handleBlur}
                handleSelect={this.props.handleSelect}
                mboxClient={this.props.mboxClient}
                noLabel
                isIntro
              />
            </div>
          </div>
          <div
            className={
              this.state.showLocationButton
                ? style.buttonWrapper
                : style.buttonWrapHidden
            }
          >
            <Button
              color="white"
              text={translations.t('Use My Location')}
              handleClick={this.useMyLocation}
              hidden={this.state.locating || this.state.rejected}
            />
          </div>
        </div>
        {this.props.video && (
          <div className={style.bkVideo}>
            <VideoBackground
              videoStyle="cover"
              src={this.props.video}
              slowFade
              poster={this.props.poster}
            />
          </div>
        )}
      </div>
    );
  }
}

export default NukemapIntro;
