import React, { Component } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import EffectSize from './effectSize';
import MobileMapButton from './mobileMapButton';
import * as style from './effectsList.module.scss';
import translations from '../../utils/translations';

const getSize = (id, ringSizes) => {
  return ringSizes.filter(ring => ring.name === id)[0].radiusMiles;
};

const sortEffects = (effects, ringSizes) =>
  effects.sort((a, b) => getSize(a.id, ringSizes) > getSize(b.id, ringSizes));

const getEffectClass = (hoveredLabelId, revealedEffects, effectId) => {
  let explosionClass = '';
  switch (revealedEffects[effectId]) {
    case 'HIDDEN':
      explosionClass = style.hiddenListItem;
      break;
    case 'ACTIVE':
      explosionClass = style.explosionActiveListItem;
      break;
    case 'INACTIVE':
      explosionClass = style.inactiveListItem;
      break;
    default:
      explosionClass = '';
  }

  return `${style.listItem} ${
    effectId === hoveredLabelId ? style.activeListItem : ''
  } ${explosionClass}`;
};

class EffectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: window.innerWidth >= 980 || this.props.mobileView !== 'Default',
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.mobileView !== nextProps.mobileView) {
      this.updateActiveState(nextProps);
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize = () => {
    this.updateActiveState();
  };
  updateActiveState = (props = this.props) => {
    this.setState({
      active: window.innerWidth >= 980 || props.mobileView === 'Effects',
    });
  };
  render() {
    return (
      <TransitionGroup>
        {this.state.active && (
          <Transition timeout={500}>
            {transitionState => (
              <div
                className={`${style.effectsList} ${
                  style['mobileTransition-' + transitionState]
                }`}
              >
                <div className={style.effectsListContent}>
                  <h2
                    className={`${
                      this.props.UIRevealed
                        ? style.effectHeader
                        : style.effectHeaderHidden
                    } f-label`}
                  >
                    {translations.t('Explore the Effects')}
                  </h2>
                  <p className={style.listDirections}>Tap to learn more</p>
                  <ul
                    className={
                      this.props.hoveredLabelId !== null
                        ? style.listHovered
                        : style.list
                    }
                  >
                    {sortEffects(this.props.effects, this.props.ringSizes).map(
                      (effect, i) => {
                        return (
                          <li
                            onMouseEnter={() => {
                              this.props.onMouseEnter(effect.id);
                            }}
                            onMouseLeave={this.props.onMouseLeave}
                            key={`effect${i}`}
                            className={getEffectClass(
                              this.props.hoveredLabelId,
                              this.props.revealedEffects,
                              effect.id
                            )}
                          >
                            <button
                              onClick={() => {
                                this.props.handleClick(effect.id);
                              }}
                            >
                              {effect.text}
                              <EffectSize
                                elClass={style.effectSize}
                                ringSizes={this.props.ringSizes}
                                id={effect.id}
                              />
                            </button>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div className={style.mobileCloseEffects}>
                  <MobileMapButton
                    handleClick={this.props.setMobileViewDefault}
                    text={translations.t('Close X')}
                    color="whiteBorder"
                    noPadding
                  />
                </div>
              </div>
            )}
          </Transition>
        )}
      </TransitionGroup>
    );
  }
}

export default EffectsList;
