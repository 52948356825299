import { navigate } from 'gatsby-link';
import React from 'react';
import { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import RoundedButton from '../buttons/roundedButton';
import * as style from './cap.module.scss';

export default function CallToActionPopup(props) {
	const ref = useRef();
	const [width, setWidth] = useState(0);
	const [collapsed, setCollapsed] = useState(false);
	const [pos, setPos] = useState(-2000);
	const [opacity, setOpacity] = useState(0);

	useLayoutEffect(() => {
		setWidth(ref.current.offsetWidth);
		setPos(props.visible ? 0 : width * -1);
		setOpacity(props.visible ? 1 : 0);
	});

	const handleClick = () => {
		navigate(props.data.link);
	};

	if (collapsed) {
		return (
			<div id="callToActionPopup" className={`${style.container} ${style.orange}`} ref={ref} style={{ right: pos }}>
				<div className={style.bodyOrange} onClick={() => setCollapsed(!collapsed)}>
					<span className={`${style.chevron} ${style.white}`}></span>
					{props.data.body}
				</div>
			</div>
		);
	}

	return (
		<>
			<div className={style.mobile} style={{ opacity }}>
				<div>
					<div className={style.title}>{props.data.title}</div>
					<div className={style.mobileBody}>{props.data.body}</div>
				</div>
				<div>
					<RoundedButton color="orange" type="button" text="Find Out" onClick={handleClick} />
				</div>
			</div>
			<div id="callToActionPopup" className={style.container} ref={ref} style={{ right: pos }}>
				<div className={style.title}>
					<span className={style.chevron} onClick={() => setCollapsed(!collapsed)}></span>
					{props.data.title}
				</div>
				<div className={style.body}>{props.data.body}</div>
				<RoundedButton color="orange" type="button" text="Keep Reading" onClick={handleClick} />
			</div>
		</>
	);
}