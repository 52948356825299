import React from 'react';

const MagnifyingGlass = () => (
  <div className="magnifyingGlass">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60.03 60.03"
      width="17px"
      height="17px"
    >
      <path
        d="M712.59,409l-5,5-19.35-19.35A21.83,21.83,0,0,1,675.07,399a22.55,22.55,0,1,1,22.51-22.51,21.83,21.83,0,0,1-4.34,13.17ZM659.9,367.68a17.44,17.44,0,1,0,6.37-6.37A17.53,17.53,0,0,0,659.9,367.68Z"
        transform="translate(-652.56 -353.97)"
      />
    </svg>
  </div>
);

export default MagnifyingGlass;
