import React from 'react';

const sqMiToKM = 2.58999;

const getSizes = (id, ringSizes) => {
  const size = ringSizes.filter(ring => ring.name === id)[0];
  return {
    sqMi: size.areaSqMiles,
    sqKm: size.areaSqKm,
  };
};

const EffectSize = props => {
  const sizes = getSizes(props.id, props.ringSizes);
  return (
    <span className={props.elClass}>
      {sizes.sqMi} mi<sup>2</sup> / {sizes.sqKm} km<sup>2</sup>
    </span>
  );
};

export default EffectSize;
