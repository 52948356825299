import React from 'react';
import Casualties from './casualties';
import SocialShare from '../global/socialShare';
import Button from '../ui/button';
import configIcon from '../../assets/config-icon.svg';
import InfoButton from '../ui/infoButton';
import translations from '../../utils/translations';

import * as style from './mapUI.module.scss';

const CasualtiesAndShare = props => (
  <div
    className={`${style.casualtiesAndShare} ${
      style['mobile' + props.mobileView]
    }`}
  >
    <Casualties
      injuries={props.injuries}
      fatalities={props.fatalities}
      explosionStatus={props.explosionStatus}
      hidden={!props.UIRevealed}
    />

    <div
      className={
        props.UIRevealed ? style.utilityLinks : style.utilityLinksHidden
      }
    >
      <SocialShare
        static
        horizontal
        title={translations.t('What would happen if a nuclear bomb went off in your backyard?')}
      />
      <div className={style.mobileButtonWrapBtwn}>
        <Button
          handleClick={props.setMobileViewEffects}
          text={translations.t('Explore Blast Effects')}
          color="whiteBorder"
          noHover
          isBlock
          morePadding
          maxWidth="300px"
        />
        <button
          onClick={props.setMobileViewEditing}
          className={style.editBombButton}
        >
          <span className="f-label">{translations.t('Bomb')}</span>
          <img src={configIcon} alt={translations.t('Configure blast')} />
        </button>
      </div>
      <div className={style.infoButtonWrap}>
        <InfoButton handleClick={props.toggleCredits} text={translations.t('about')} />
      </div>
    </div>
  </div>
);

export default CasualtiesAndShare;
