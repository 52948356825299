import React from 'react';
import ReactDOM from 'react-dom';

import * as style from './clickCatcher.module.scss';

class ClickCatcher extends React.Component {
  constructor(props) {
    super(props);
    if (typeof this.props.portalId !== 'undefined') {
      this.el = document.getElementById(this.props.portalId);
    }
  }
  render() {
    const content = (
      <div
        onClick={this.props.handleClick}
        className={
          this.props.noMobile ? style.clickCatcherNoMobile : style.clickCatcher
        }
      />
    );
    if (typeof this.props.portalId !== 'undefined') {
      return ReactDOM.createPortal(content, this.el);
    } else {
      return content;
    }
  }
}

export default ClickCatcher;
