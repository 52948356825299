// extracted by mini-css-extract-plugin
export var effectsList = "effectsList-module--effectsList--2DAxz";
export var effectsListContent = "effectsList-module--effectsListContent--vuqaa";
export var effectSize = "effectsList-module--effectSize--ds2JD";
export var effectHeader = "effectsList-module--effectHeader--rmtFi";
export var effectHeaderHidden = "effectsList-module--effectHeaderHidden--KMrgK";
export var list = "effectsList-module--list--r-GP9";
export var listItem = "effectsList-module--listItem--OyXqe";
export var explosionActiveListItem = "effectsList-module--explosionActiveListItem---VE3v";
export var inactiveListItem = "effectsList-module--inactiveListItem--LjaD0";
export var hiddenListItem = "effectsList-module--hiddenListItem--vUjZA";
export var listHovered = "effectsList-module--listHovered--5EQVX effectsList-module--list--r-GP9";
export var activeListItem = "effectsList-module--activeListItem---RzZW";
export var closeEffects = "effectsList-module--closeEffects--IcFVm";
export var listDirections = "effectsList-module--listDirections--H0P6-";
export var mobileCloseEffects = "effectsList-module--mobileCloseEffects--fTE9H";
export var mobileTransitionEntering = "effectsList-module--mobileTransition-entering--JRGig";
export var slideInUp = "effectsList-module--slideInUp--Q6Uk7";
export var mobileTransitionExiting = "effectsList-module--mobileTransition-exiting--spB4E";
export var slideOutDown = "effectsList-module--slideOutDown--D3OOz";
export var slideUp = "effectsList-module--slideUp--Em4m6";
export var slideUpLarge = "effectsList-module--slideUpLarge--10zk-";