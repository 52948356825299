import vertexShaderSource from '../../webgl/shaders/simpleVertex';
import fragmentShaderSource from '../../webgl/shaders/distortFrag';
import webglUtils from '../../webgl/webglUtils';
import m4 from '../../webgl/m4';
import createTextureInfo from '../../webgl/utils/createTextureInfo';

export default class WebGLSequencer {
  constructor(canvas) {
    this.setupWebGL(canvas);
  }
  setupWebGL(canvas) {
    this.gl =
      canvas.getContext('webgl', {
        antialias: false,
        premultipliedAlpha: true,
      }) ||
      canvas.getContext('experimental-webgl', {
        antialias: false,
        premultipliedAlpha: true,
      });
    if (!this.gl) {
      return;
    }
    this.gl.enable(this.gl.BLEND);

    // use premultiplied alpha for img textures
    this.gl.blendFunc(this.gl.ONE, this.gl.ONE_MINUS_SRC_ALPHA);
    this.gl.pixelStorei(this.gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);

    // setup GLSL program
    this.program = webglUtils.createProgramFromScripts(this.gl, [
      { text: vertexShaderSource, type: 'x-shader/x-vertex' },
      { text: fragmentShaderSource, type: 'x-shader/x-fragment' },
    ]);
    // look up where the vertex data needs to go.
    this.positionLocation = this.gl.getAttribLocation(
      this.program,
      'a_position'
    );
    this.texcoordLocation = this.gl.getAttribLocation(
      this.program,
      'a_texcoord'
    );
    // lookup uniforms
    this.matrixLocation = this.gl.getUniformLocation(this.program, 'u_matrix');
    this.textureLocation = this.gl.getUniformLocation(
      this.program,
      'u_texture'
    );
    this.timeLocation = this.gl.getUniformLocation(this.program, 'u_time');
    this.freqLocation = this.gl.getUniformLocation(this.program, 'u_freq');
    this.ampLocation = this.gl.getUniformLocation(this.program, 'u_amp');
    this.alphaLocation = this.gl.getUniformLocation(this.program, 'u_alpha');

    // Create a buffer.
    this.positionBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);

    // Put a unit quad in the buffer
    var positions = [0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1];
    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      new Float32Array(positions),
      this.gl.STATIC_DRAW
    );

    // Create a buffer for texture coords
    this.texcoordBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texcoordBuffer);

    // Put texcoords in the buffer
    var texcoords = [0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1];
    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      new Float32Array(texcoords),
      this.gl.STATIC_DRAW
    );
    this.then = 0;
    this.globalTime = 0;
  }
  loadTextureSequence(images) {
    return images.map(img => createTextureInfo(img, this.gl));
  }

  draw(time, drawLayers) {
    var now = time * 0.001;
    var deltaTime = Math.min(0.1, now - this.then);
    this.globalTime = this.globalTime + deltaTime;
    // console.log(this.gl.canvas.width);
    // webglUtils.resizeCanvasToDisplaySize(this.gl.canvas);
    // Tell WebGL how to convert from clip space to pixels
    this.gl.viewport(0, 0, this.gl.canvas.width, this.gl.canvas.height);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    if (drawLayers.length > 0) {
      drawLayers.forEach(layer => {
        this.drawImage(layer);
      });
    }
  }

  // Unlike images, textures do not have a width and height associated
  // with them so we'll pass in the width and height of the texture
  drawImage({
    textureInfoGetter,
    x,
    y,
    width,
    height,
    frequency,
    amplitude,
    timeOffset,
    alpha,
  }) {
    const textureInfo = textureInfoGetter();
    if (!textureInfo) {
      return;
    }

    this.gl.bindTexture(this.gl.TEXTURE_2D, textureInfo.texture);

    // Tell WebGL to use our shader program pair
    this.gl.useProgram(this.program);

    // Setup the attributes to pull data from our buffers
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
    this.gl.enableVertexAttribArray(this.positionLocation);
    this.gl.vertexAttribPointer(
      this.positionLocation,
      2,
      this.gl.FLOAT,
      false,
      0,
      0
    );
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texcoordBuffer);
    this.gl.enableVertexAttribArray(this.texcoordLocation);
    this.gl.vertexAttribPointer(
      this.texcoordLocation,
      2,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    // this matirx will convert from pixels to clip space
    var matrix = m4.orthographic(
      0,
      this.gl.canvas.width,
      this.gl.canvas.height,
      0,
      -1,
      1
    );

    // this matrix will translate our quad to dstX, dstY
    matrix = m4.translate(matrix, x, y, 0);

    // this matrix will scale our 1 unit quad
    // from 1 unit to target size width and height
    matrix = m4.scale(matrix, width, height, 1);

    // Set the matrix.
    this.gl.uniformMatrix4fv(this.matrixLocation, false, matrix);

    // Tell the shader to get the texture from texture unit 0
    this.gl.uniform1i(this.textureLocation, 0);

    // Set uniforms
    this.gl.uniform1f(this.timeLocation, this.globalTime + timeOffset);
    this.gl.uniform1f(this.freqLocation, frequency);
    this.gl.uniform1f(this.ampLocation, amplitude);
    this.gl.uniform1f(this.alphaLocation, alpha);

    // draw the quad (2 triangles, 6 vertices)
    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);
  }
}
