import React, { Component } from 'react';
import mapboxgl from '!mapbox-gl';
// import mapboxgl from 'mapbox-gl';
import { Transition, TransitionGroup } from 'react-transition-group';
import getSquareCoords from './utils/getSquareCoords';
import * as style from './nukemap.module.scss';
import conversions from './utils/conversions';
import createGeoJSONCircles from './utils/createGeoJSONCircle';
import MapLoadingScreen from './mapLoadingScreen';
import WebGLSequencer from './webGLSequencer';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MAXZOOM = 14;
const MINZOOM = 5;

const targetLayerSizes = {
  lg: {
    thermal: 550,
    blast_psi5: 500,
    fireball: 150,
    radiation: 250,
  },
  sm: {
    thermal: 350,
    blast_psi5: 350,
    fireball: 150,
    radiation: 250,
  },
};

const getBoundsPadding = (layerId, bombKT, isViewingEffect, mobileView) => {
  // tsar bomba (or large KT): make it bigger on screen
  if (bombKT > 10000) {
    if (!isViewingEffect) {
      return {
        top: window.innerWidth >= 768 ? 60 : 10,
        bottom: 10,
        left: 10,
        right: 10,
      };
    }
  }
  const targetSize =
    window.innerWidth >= 600
      ? targetLayerSizes.lg[layerId]
      : targetLayerSizes.sm[layerId];

  const vert = (window.innerHeight - targetSize) / 2;
  const horz = (window.innerWidth - targetSize) / 2;
  const windowWidth = window.innerWidth;
  const windowHeightAdjust = window.innerHeight * 0.11;

  if (isViewingEffect) {
    if (window.innerWidth >= 980) {
      return {
        top: vert,
        bottom: vert,
        left: (windowWidth / 2 - targetSize) / 2,
        right: (windowWidth + (windowWidth / 2 - targetSize)) / 2,
      };
    } else {
      return {
        top: vert - windowHeightAdjust,
        bottom: vert + windowHeightAdjust,
        left: horz,
        right: horz,
      };
    }
  } else if (window.innerWidth < 980 && mobileView === 'Effects') {
    return {
      top: vert - windowHeightAdjust,
      bottom: vert + windowHeightAdjust,
      left: horz,
      right: horz,
    };
  }
  return { top: vert, bottom: vert, left: horz, right: horz };
};

class Map extends Component {
  mounted = false;
  constructor(props) {
    super(props);
    this.isExploding = true;
    this.mapLoaded = false;
    this.state = {
      showLoadingScreen: true,
      loadTextStatus: 'BEFORE',
    };
  }
  componentDidMount() {
    this.mounted = true;
    this.webGLSeq = new WebGLSequencer(this.canvas);
    this.setCanvasSize();
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/outrider/cjgf4hurg001h2slzilh8wz33',
      center: [this.props.longitude, this.props.latitude],
      attributionControl: false,
      zoom: 8,
      maxZoom: MAXZOOM,
      minZoom: MINZOOM,
      doubleClickZoom: true,
      dragRotate: false,
      pitchWithRotate: false,
    }).addControl(
      new mapboxgl.AttributionControl({
        compact: true,
      })
    );
    this.map.on('load', this.onMapLoad);
    this.map.touchZoomRotate.disableRotation();
    this.map.scrollZoom.disable();
    this.map.scrollZoom.enable({ around: 'center' });
    this.map.on('zoomend', () => {
      if (this.isFittingBounds) {
        this.finishFittingBounds();
      }
    });
    window.addEventListener('resize', this.setCanvasSize);
  }
  finishFittingBounds = () => {
    this.isFittingBounds = false;
    this.map.setMinZoom(MINZOOM);
    this.map.setMaxZoom(MAXZOOM);
  };
  startFittingBounds = () => {
    this.isFittingBounds = true;
    this.map.setMinZoom(0);
    this.map.setMaxZoom(24);
  };
  onMapLoad = () => {
    this.props.setMapLoaded();
    this.mountTime = Date.now();

    this.setState({ loadTextStatus: 'ON' });
    this.mapLoaded = true;
    this.map.resize();

    this.marker = new mapboxgl.Marker(undefined, { offset: [-3, 0] })
      .setLngLat([this.props.longitude, this.props.latitude])
      .addTo(this.map);

    this.map.addSource(
      'rings',
      createGeoJSONCircles(
        [this.props.longitude, this.props.latitude],
        this.props.rings
      )
    );
    this.map.addLayer({
      id: 'ringLayer',
      type: 'fill',
      source: 'rings',
      paint: {
        'fill-color': '#fff',
        'fill-opacity': 0,
      },
    });
    this.map.on('mousemove', 'ringLayer', e => {
      if (this.isExploding) {
        return;
      }
      this.map.getCanvas().style.cursor = 'pointer';
      this.props.ringHover(e.features[0].properties.name);
    });
    this.map.on('click', 'ringLayer', e => {
      this.props.ringClick(e.features[0].properties.name);
    });
    this.map.on('mouseleave', 'ringLayer', e => {
      this.map.getCanvas().style.cursor = 'default';
      this.props.ringHover();
    });

    this.map.on('move', e => {
      this.updateDrawData();
    });

    this.updateMapSizing(this.props, false);
    window.addEventListener('resize', this.handleResize);
    // console.log('map loaded');
    if (this.props.imagesLoaded) {
      this.onMapAndImagesLoaded();
    }
  };
  loadLayerTextures() {
    Object.keys(this.props.layers).forEach(k => {
      const layer = this.props.layers[k];
      const imgArray = Object.keys(layer.imgs).map(key => layer.imgs[key]);
      layer.setTextureData(this.webGLSeq.loadTextureSequence(imgArray));
    });
  }
  getExplosionPixelSize() {
    const box = getSquareCoords(
      [this.props.longitude, this.props.latitude],
      this.getLargestRingSize()
    ).boundingBox;
    const points = [this.map.project(box[0]), this.map.project(box[1])];

    return {
      left: points[0].x,
      right: points[1].x,
      top: points[1].y,
      bottom: points[0].y,
      width: points[1].x - points[0].x,
      height: points[0].y - points[1].y,
    };
  }
  updateDrawData = (props = this.props) => {
    // only calculate this when it needs to change rather than in draw loop.
    // Makes it faster
    const explosionDimensions = this.getExplosionPixelSize();

    this.drawData = props.rings.map(ring => {
      const layer = props.layers[ring.name];
      const size =
        explosionDimensions.width * ring.ratio * layer.getMultiplier();

      const distFromExplosionEdge = (explosionDimensions.width - size) / 2;

      if (this.isExploding) {
        layer.setOpacity(1);
      } else if (
        (props.hoveredLabelId && props.hoveredLabelId !== ring.name) ||
        (props.selectedLabelId && props.selectedLabelId !== ring.name)
      ) {
        layer.setOpacity(0.25);
      } else {
        layer.setOpacity(1);
      }

      layer.setSize(size);
      layer.setPos(
        explosionDimensions.left + distFromExplosionEdge,
        explosionDimensions.top + distFromExplosionEdge
      );
      return layer.getDrawData();
    });
  };

  onImagesLoaded = () => {
    if (this.mapLoaded) {
      this.onMapAndImagesLoaded();
    }
  };

  onMapAndImagesLoaded = () => {
    this.loadLayerTextures();
    this.updateDrawData();

    const goalLoadScreenTime = 4000;
    const itTook = Date.now() - this.mountTime;
    if (itTook < goalLoadScreenTime) {
      this.loadTimeout = setTimeout(
        this.finishMapLoad,
        goalLoadScreenTime - itTook
      );
    } else {
      this.finishMapLoad();
    }
  };
  finishMapLoad = () => {
    this.setState({ showLoadingScreen: false });
    const marker = document.querySelector('.mapboxgl-marker');
    if (marker) {
      marker.classList.add('hidden');
    }
    this.markerTimeout = setTimeout(() => {
      this.marker.remove();
    }, 1000);
    this.startCanvas();
    this.explosionTimeout = setTimeout(() => {
      this.runExplosion(true);
    }, 1000);
  };

  componentWillReceiveProps(nextProps) {
    let updateDraw = false;
    if (!this.props.imagesLoaded && nextProps.imagesLoaded) {
      this.onImagesLoaded();
    }

    // zoom in and out for effect views
    if (this.props.selectedLabelId !== nextProps.selectedLabelId) {
      updateDraw = true;
      if (nextProps.selectedLabelId) {
        this.zoomToEffect(nextProps, nextProps.selectedLabelId);
      } else {
        // zooming to thermal zooms out to full size
        this.zoomToEffect(nextProps, 'thermal');
      }
    }
    // if (this.props.bombKT !== nextProps.bombKT) {
    //   updateDraw = true;
    //   this.runExplosion(false, 1500);
    //   this.updateRings(nextProps);
    //   this.updateMapSizing(nextProps, true);
    // }
    if (
      this.props.bombKT !== nextProps.bombKT ||
      this.props.longitude !== nextProps.longitude ||
      this.props.latitude !== nextProps.latitude ||
      this.props.airburst !== nextProps.airburst
    ) {
      // resize explosion if parameters change
      updateDraw = true;
      this.runExplosion(false, 1200);
      this.updateRings(nextProps);

      const doAnimate =
        (this.props.bombKT !== nextProps.bombKT ||
          this.props.airburst !== nextProps.airburst) &&
        this.props.mobileView !== 'Editing';

      this.updateMapSizing(nextProps, doAnimate);
    }
    if (this.props.hoveredLabelId !== nextProps.hoveredLabelId) {
      updateDraw = true;
    }
    // mobile detonate button triggers explosion
    if (
      this.props.mobileView === 'Editing' &&
      nextProps.mobileView === 'Default'
    ) {
      this.updateMapSizing(nextProps, false);
      this.runExplosion(false, 1000);
    } else if (this.props.mobileView !== nextProps.mobileView) {
      this.updateMapSizing(nextProps);
    }
    if (updateDraw) {
      this.updateDrawData(nextProps);
    }
  }
  componentWillUnmount() {
    this.mounted = false;
    cancelAnimationFrame(this.raf);
    clearTimeout(this.markerTimeout);
    clearTimeout(this.explosionTimeout);
    clearTimeout(this.loadTimeout);
    // clearInterval(this.interval);

    window.removeEventListener('resize', this.handleResize);
    this.map.remove();
  }
  handleResize = () => {
    this.map.resize();
  };

  // Update Map Data
  updateMapSizing(props, doAnimate) {
    const newBox = getSquareCoords(
      [props.longitude, props.latitude],
      this.getLargestRingSize(props)
    );
    this.startFittingBounds();
    setTimeout(() => {
      this.map.fitBounds(newBox.boundingBox, {
        padding: getBoundsPadding(
          'thermal',
          props.bombKT,
          props.isViewingEffect,
          props.mobileView
        ),
        animate: doAnimate,
      });
    }, 10);
  }
  updateRings(props = this.props) {
    this.map
      .getSource('rings')
      .setData(
        createGeoJSONCircles([props.longitude, props.latitude], props.rings)
          .data
      );
  }

  // Map Behavior
  zoomToEffect(props = this.props, effectId) {
    this.startFittingBounds();
    this.map.fitBounds(
      getSquareCoords(
        [props.longitude, props.latitude],
        props.rings.filter(ring => ring.name === effectId)[0].radiusMiles *
        conversions.mi2km
      ).boundingBox,
      {
        padding: getBoundsPadding(
          effectId,
          props.bombKT,
          props.isViewingEffect,
          props.mobileView
        ),
        speed: 0.4,
        animate: true,
      }
    );
  }

  runExplosion = (withText, delay) => {
    clearTimeout(this.explodeTimeout);
    this.isExploding = true;
    if (withText) {
      this.props.handleExplosionStart();
    }
    Object.keys(this.props.layers).map(k =>
      this.props.layers[k].explode(withText, delay)
    );

    this.explodeTimeout = setTimeout(() => {
      this.isExploding = false;
      this.updateDrawData();
    }, 7000);
  };
  startCanvas() {
    // this.interval = setInterval(this.drawFrame, 34);
    this.raf = requestAnimationFrame(this.drawFrame);
  }
  drawFrame = rafTime => {
    this.raf = requestAnimationFrame(this.drawFrame);
    this.webGLSeq.draw(rafTime, this.drawData);
  };
  setCanvasSize = () => {
    if (this.mounted) {
      this.setState(
        {
          canvasWidth: document.body.clientWidth,
          canvasHeight:
            document.body.clientWidth < 980
              ? document.body.clientHeight - 40
              : document.body.clientHeight,
        },
        this.updateDrawData
      );
    }
  };
  getLargestRingSize(props = this.props) {
    return (
      props.rings.filter(ring => ring.name === 'thermal')[0].radiusMiles *
      conversions.mi2km
    );
  }

  render() {
    return (
      <div
        ref={el => {
          this.wrapper = el;
        }}
        className={`${style.map} ${style['mobile' + this.props.mobileView]} ${this.props.UIRevealed ? style.mapUIRevealed : ''
          }`}
      >
        <TransitionGroup>
          {this.state.showLoadingScreen && (
            <Transition timeout={2500}>
              {state => (
                <MapLoadingScreen
                  progress={this.props.loadProgress}
                  loadTextStatus={this.state.loadTextStatus}
                  state={state}
                />
              )}
            </Transition>
          )}
        </TransitionGroup>
        <div
          className={
            this.props.mapLoaded ? style.mapContainer : style.mapContainerHidden
          }
          ref={el => (this.mapContainer = el)}
        />
        <canvas
          ref={el => {
            this.canvas = el;
          }}
          width={this.state.canvasWidth}
          height={this.state.canvasHeight}
          id="bombCanvas"
          className={style.bombCanvas}
        />
      </div>
    );
  }
}

export default Map;
