import React, { Component } from 'react';
import * as style from './mapLoadingScreen.module.scss';
import ProgressBar from '../ui/progressBar';
import translations from '../../utils/translations';

class MapLoadingScreen extends Component {
  mounted = false;
  constructor() {
    super();
    this.state = {
      complete: false,
    };
  }
  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  componentWillReceiveProps(nextProps) {
    if (!this.state.complete && nextProps.progress >= 1) {
      setTimeout(() => {
        if (this.mounted) this.setState({ complete: true });
      }, 2000);
    }
  }
  render() {
    const { t } = this.props;
    return (
      <div
        className={`${this.state.complete ? style.loadComplete : ''} ${
          this.props.state === 'exiting'
            ? style.loadingScreenExit
            : style.loadingScreen
        }`}
      >
        <div className={style.progressWrapper}>
          <ProgressBar progress={this.props.progress} delay={1000} />
        </div>
        <div className={style[`header${this.props.loadTextStatus}`]}>
          <h1>{translations.t('What would happen if a nuclear bomb went off here?')}</h1>
        </div>
        <div className={style.loadingText}>{translations.t('Loading')}</div>
      </div>
    );
  }
}
export default MapLoadingScreen;
