class DetonateListener {
  callbacks = [];

  subscribe(callback) {
    this.callbacks.push(callback);
    return () => {
      this.callbacks = this.callbacks.filter(
        eventCallback => eventCallback !== callback
      );
    };
  }
  detonate() {
    this.callbacks.forEach(fn => {
      fn();
    });
  }
}

export default new DetonateListener();
