import React from 'react';
import Button from '../ui/button';
import * as style from './mapUI.module.scss';

const MobileMapButton = props => (
  <div
    className={`${style.mobileButtonWrap} ${
      props.noPadding ? style.noPadding : ''
    }`}
  >
    <Button {...props} noHover isBlock morePadding maxWidth="300px" />
  </div>
);

export default MobileMapButton;
