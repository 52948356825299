import React, { Component } from 'react';
import * as style from './bombSettings.module.scss';
import DetonateListener from './detonateListener';
import BombSelector from './bombSelector';
import LocationSelector from './locationSelector';
import BurstType from './burstType';
import BombIcon from '../ui/icons/bombIcon';
import LocationPin from '../ui/icons/locationPin';
import MobileMapButton from './mobileMapButton';

class MapControls extends Component {
  resetUIScroll() {
    this.controlFrame.scrollTop = 0;
  }
  handleDetonate = () => {
    DetonateListener.detonate();
    setTimeout(() => {
      this.props.setMobileViewDefault();
    }, 100);
  };
  render() {
    return (
      <div
        className={`${
          this.props.hidden ? style.nukemapBarHidden : style.nukemapBar
        } ${style['mobile' + this.props.mobileView]} `}
      >
        <div
          className={style.bombSettings}
          ref={el => {
            this.controlFrame = el;
          }}
        >
          <div className={style.bombSettingsInner}>
            <LocationSelector
              updateLocation={this.props.updateLocation}
              currentLocationName={this.props.currentLocationName}
              id="mapBarLocation"
              mboxClient={this.props.mboxClient}
            />
            <BombSelector
              bombTypes={this.props.bombTypes}
              currentBombIndex={this.props.currentBombIndex}
              updateBomb={this.props.updateBomb}
            />
            <BurstType
              airburst={this.props.airburst}
              toggleAirBurst={this.props.toggleAirBurst}
              toggleSurfaceBurst={this.props.toggleSurfaceBurst}
            />
          </div>
          <div className={style.mobileBombEditButtonContainer}>
            <MobileMapButton
              handleDown={this.handleDetonate}
              text="Detonate"
              color="white"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MapControls;
