import React from 'react';
import * as style from './loadingScreen.module.scss';

const LoadingScreen = () => (
  <div className={style.loadingScreen}>
    <div className={style.pulse} />
  </div>
);

export default LoadingScreen;
