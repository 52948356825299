import axios from 'axios';

const getCasualties = (
  { latitude, longitude, kt, isAirburst, burstHeightFeet },
  update
) => {
  axios
    .get('/.netlify/functions/getCasualties', {
      params: {
        lat: latitude,
        lng: longitude,
        kt: kt,
        airburst: isAirburst ? 1 : 0,
        hob_opt: isAirburst ? 1 : 0,
        hob_ft: isAirburst ? burstHeightFeet : 0,
      },
    })
    .then(response => {
      if (response.data && response.data.fatalities) {
        update(
          parseInt(response.data.fatalities, 10).toLocaleString(),
          parseInt(response.data.injuries, 10).toLocaleString()
        );
      }
      // this.setState({ data: response.data });
    })
    .catch(error => {
      console.log(error);
    });
};

export default getCasualties;
