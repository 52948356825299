import { graphql } from 'gatsby';
import React, { Component } from 'react';
import BombBlast from '../components/nukemap';
import DefaultLayout from '../layouts/default-layout';
import { navigate } from 'gatsby';

/*
class BombBlastTemplate extends Component {
    componentDidMount() {
        navigate('/ukraine')
    }
  
    render() {
        return (
            <div></div>
        )
    }
}
export default BombBlastTemplate;
*/

const BombBlastTemplate = props => (
  <DefaultLayout {...props}>
    <BombBlast {...props} style={props.transition && props.transition.style} />
  </DefaultLayout>
);

export default BombBlastTemplate;

export const bombBlastTemplateQuery = graphql`
  query bombBlastTemplateQuery(
      $path: String!
      $idRegex: String!
      $isEs: Boolean!
      $blog: String!
      $translations: String!
    ) {
    interactive: itemsJson(path: { eq: $path }) {
      title
      meta_title
      meta_description
      meta_image {
        sizes {
          hero_md
          hero_sm
        }
      }
      featured_image {
        image {
          sizes {
            hero_lg
            hero_md
            hero_sm
          }
        }
      }
      video {
        url
      }
      intro_description
      credit_body
      callout_popup {
        title
        body
        link
      }
    }
    bombs: allItemsJson(filter: { content_type: { eq: "bomb" }, jsonId: {regex: $idRegex} }) {
      edges {
        node {
          title
          content_type
          bomb_yield
          bomb_yield_label
          bomb_height
          subtitle
          created_date
          featured_image {
            image {
              sizes {
                original
              }
            }
          }
        }
      }
    }
    effects: allItemsJson(filter: { content_type: { eq: "bomb_effect" }, jsonId: {regex: $idRegex} }) {
      edges {
        node {
          title
          id
          content_type
          bomb_effect_layer_type
          path
          featured_image {
            image {
              title
              sizes {
                landscape_sm
              }
            }
          }
          video {
            url
          }
          ...queryParagraphFields
        }
      }
    }
  
  # index content
  ...headerFields @skip(if: $isEs)
  ...footerFields @skip(if: $isEs)
  ...headerFieldsEs @include(if: $isEs)
  ...footerFieldsEs @include(if: $isEs)
  challenges: allItemsJson(
    filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
  ) {
    edges {
      node {
        title
        content_type
        path
        related_challenge {
          title
          path
        }
        core_curriculum {
          title
          path
        }
      }
    }
  }
  pages: allItemsJson(
    filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
  ) {
    edges {
      node {
        title
        content_type
        path
      }
    }
  }
  blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
    edges {
      node {
        title
        content_type
        path
      }
    }
  }
  translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
    edges {
      node {
        translations {
          id
          key
          value
        }
      }
    }
  }
  megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
      projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
  }
`;