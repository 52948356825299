export default `precision mediump float;

varying vec2 v_texcoord;

uniform sampler2D u_texture;
uniform float u_time;
uniform float u_freq;
uniform float u_amp;
uniform float u_alpha;

float PI = 3.14159265359;
float interval = 1000.0 / 60.0;

void main() {

  float T =  u_time * interval * u_freq / 100.0;
  vec2 pos = v_texcoord.xy;

  float xs = u_amp * sin(2.0 * PI * (3.0 * pos.y + T));
  float ys = u_amp * cos(2.0 * PI * (3.0 * pos.x  + T));

  gl_FragColor = texture2D(u_texture, vec2(pos.x + xs, pos.y + ys)) * u_alpha;

}`;
