// extracted by mini-css-extract-plugin
export var effectSize = "effectContent-module--effectSize--n-5ww";
export var effectText = "effectContent-module--effectText--YqhBc";
export var effectTextInner = "effectContent-module--effectTextInner--yMoJT";
export var mobileImgWrap = "effectContent-module--mobileImgWrap--s0hUy";
export var entering = "effectContent-module--entering--VfYom";
export var slideInUp = "effectContent-module--slideInUp--m0TBG";
export var slideIn = "effectContent-module--slideIn--SrUPd";
export var exiting = "effectContent-module--exiting--e9ci1";
export var slideOutDown = "effectContent-module--slideOutDown--kouqx";
export var slideOut = "effectContent-module--slideOut--14kqr";
export var slideUp = "effectContent-module--slideUp--9Pka1";
export var slideUpLarge = "effectContent-module--slideUpLarge--wqzC-";