export function trackLocationUse() {
  if (typeof window !== 'undefined' && typeof dataLayer !== 'undefined') {
    /* eslint-disable-next-line no-undef */
    dataLayer.push({
      event: 'gaEvent',
      gaCategory: 'Nuclear Weapons',
      gaAction: 'Filter Use',
      gaLabel: 'Enter Location',
      gaValue: 60,
    });
  }
}

export function trackInfographicHover() {
  if (typeof window !== 'undefined' && typeof dataLayer !== 'undefined') {
    /* eslint-disable-next-line no-undef */
    dataLayer.push({
      event: 'gaEvent',
      gaCategory:
        window.location.href.indexOf('nuclear-weapons') !== -1
          ? 'Nuclear Weapons'
          : 'Climate Change',
      gaAction: 'Hover on Image',
      gaLabel: window.location.href,
      gaValue: 20,
    });
  }
}
