const conversions = {
  mi2km: 1.60934,
  mi2m: 1609.34,
  km2ft: 3280.84,
  m2ft: 3.28084,
  ft2m: 0.3048,
  ft2km: 0.0003048,
  ft2mi: 0.000189394,
  m2mi: 0.000621371,
  mi2ft: 5280,
  km2mi: 0.621371,
  rad2deg: 180 / Math.PI,
  deg2rad: Math.PI / 180,
};

export default conversions;
