import conversions from './conversions';

function createRing(center, circleObj, holeObj, points = 120) {
  var coords = {
    latitude: center[1],
    longitude: center[0],
  };

  var km = circleObj.radiusMiles * conversions.mi2km;

  var ret = [];
  var distanceX = km / (111.32 * Math.cos(coords.latitude * Math.PI / 180));
  var distanceY = km / 110.574;

  var theta, x, y;
  for (var i = 0; i < points; i++) {
    theta = i / points * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }

  var hole = [];
  var holeKm = holeObj.radiusMiles * conversions.mi2km;
  var distanceXSmall =
    holeKm / (111.32 * Math.cos(coords.latitude * Math.PI / 180));
  var distanceYSmall = holeKm / 110.574;
  for (var j = 0; j < points; j++) {
    theta = j / points * (2 * Math.PI);
    x = distanceXSmall * Math.cos(theta);
    y = distanceYSmall * Math.sin(theta);

    hole.push([coords.longitude + x, coords.latitude + y]);
  }
  hole.push(hole[0]);

  return {
    type: 'Feature',
    properties: {
      name: circleObj.name,
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [[ret, hole]],
    },
  };
}

function createCircle(center, circleObj, points = 120) {
  var coords = {
    latitude: center[1],
    longitude: center[0],
  };

  var km = circleObj.radiusMiles * conversions.mi2km;

  var ret = [];
  var distanceX = km / (111.32 * Math.cos(coords.latitude * Math.PI / 180));
  var distanceY = km / 110.574;

  var theta, x, y;
  for (var i = 0; i <= points; i++) {
    theta = i / points * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }

  return {
    type: 'Feature',
    properties: {
      name: circleObj.name,
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [[ret]],
    },
  };
}

export default function createGeoJSONCircles(center, circleObjs, points = 120) {
  let circles;
  if (Array.isArray(circleObjs)) {
    circles = circleObjs.map((circleObj, i) => {
      return createCircle(center, circleObj, points);
      // using cicles, not rings at the moment

      // if (i === 0 ) {
      //   return createCircle(center, circleObj, points);
      // } else {
      //   return createRing(center, circleObj, circleObjs[i - 1], points);
      // }
    });
  } else {
    circles = [createCircle(center, circleObjs, points)];
  }
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: circles,
    },
  };
}
