import React, { Component } from 'react';
import TextAnimator from '../ui/textAnimator';
import translations from '../../utils/translations';

import * as style from './casualties.module.scss';
const explosionTime = 8500;

class Casualties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.explosionStatus === 'AFTER',
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.explosionStatus === 'DURING' &&
      nextProps.explosionStatus === 'AFTER'
    ) {
      this.setState({ show: true });
    }
  }

  render() {
    return (
      <div
        className={
          this.state.show && !this.props.hidden
            ? style.casualties
            : style.casualtiesHidden
        }
      >
        {/* <p className={style.notice}>
          <span style={{ color: '#ea5600' }}>Notice:</span>
          <br /> Due to heavy traffic, casualty data may be slow to load or
          unresponsive.{' '}
        </p> */}

        <ul>
          <li>
            <span className="f-label">{translations.t('Fatalities')}</span>
            <span className={style.fatalityCount}>
              <TextAnimator
                text={this.props.fatalities}
                show={this.state.show}
              />
            </span>
          </li>
          <li>
            <span className="f-label">{translations.t('Injuries')}</span>
            <span className={style.casualtyCount}>
              <TextAnimator text={this.props.injuries} show={this.state.show} />
            </span>
          </li>
        </ul>
      </div>
    );
  }
}

export default Casualties;
