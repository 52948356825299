import MapboxClient from 'mapbox';
import qs from 'query-string';
import React, { PureComponent } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import translations from '../../utils/translations';
import HeadTags from '../global/headTags';
import LoadingScreen from '../global/loadingScreen';
import CasualtiesAndShare from './casualtiesAndShare';
import EffectsList from './effectsList';
import EffectView from './effectView';
import InteractiveCredits from './interactiveCredits';
import LayerManager from './layerManager';
import Map from './map';
import MapControls from './mapControls';
import MapUIContainer from './mapUIContainer';
import * as style from './nukemap.module.scss';
import NukemapIntro from './nukemapIntro';
import explosionRings from './utils/explosionRings';
import { globalHistory } from '@reach/router/lib/history';
import { navigate } from 'gatsby';
import eventBus from '../../utils/eventBus';
import CallToActionPopup from '../global/callToActionPopup';

let getCasualties;
const mboxClient = new MapboxClient(process.env.REACT_APP_MAPBOX_TOKEN);

class BombBlast extends PureComponent {
  mounted = false;

  constructor(props) {
    super(props);
    // bomb data never changes so it's ok to keep it here
    this.bombTypeData = this.props.data.bombs.edges
      .sort(
        (a, b) =>
          parseInt(a.node.bomb_yield, 10) - parseInt(b.node.bomb_yield, 10)
      )
      .map(({ node }) => ({
        src: node.featured_image.image.sizes.original,
        name: node.title,
        description: node.subtitle,
        yieldLabel: node.bomb_yield_label,
        kt: parseInt(node.bomb_yield, 10),
        bombHeight: parseInt(node.bomb_height, 10),
      }));

    this.effectsListData = this.props.data.effects.edges.map(({ node }) => ({
      path: node.path,
      text: node.title,
      id: node.bomb_effect_layer_type,
      paragraphs: node.paragraphs,
      title: node.title,
      image: (node.featured_image && node.featured_image.image)
        ? node.featured_image.image.sizes.landscape_sm
        : null,
      imageAlt: (node.featured_image && node.featured_image.image) ? node.featured_image.image.title : null,
      videoBackground: node.video ? node.video.url : null,
    }));

    this.layers = {
      thermal: new LayerManager({
        imgName: 'thermal',
        explosionDelay: 1600,
        ratioMultiplier: 1,
        seqLength: 59,
        onReveal: () => {
          this.revealEffect('thermal', 'fireball');
        },
        loadCallback: this.onImgLoaded,
        timeOffset: 0.1,
        frequency: 0.2,
        amplitude: 0.007,
      }),
      blast_psi5: new LayerManager({
        imgName: 'shockwave',
        explosionDelay: 3100,
        ratioMultiplier: 1.02,
        seqLength: 60,
        onReveal: () => {
          this.revealEffect('blast_psi5', 'thermal');
        },
        loadCallback: this.onImgLoaded,
        timeOffset: 0.25,
        frequency: 0.35,
        amplitude: 0.005,
      }),
      radiation: new LayerManager({
        imgName: 'radiation',
        explosionDelay: 4600,
        ratioMultiplier: 1.07,
        seqLength: 50,
        onReveal: () => {
          this.revealEffect('radiation', 'blast_psi5');
          setTimeout(this.handleExplosionComplete, 2500);
        },
        loadCallback: this.onImgLoaded,
        timeOffset: 0.5,
        frequency: 1,
        amplitude: 0.0025,
      }),
      fireball: new LayerManager({
        imgName: 'fireball',
        explosionDelay: 0,
        ratioMultiplier: 4,
        seqLength: 75,
        onReveal: () => {
          this.revealEffect('fireball');
        },
        loadCallback: this.onImgLoaded,
        timeOffset: 0.75,
        frequency: 1,
        amplitude: 0.002,
      }),
    };

    this.numImagesLoaded = 0;
    this.numImagesToLoad = Object.keys(this.layers).reduce(
      (prev, next) => prev + this.layers[next].seqLength + 1,
      0
    );

    let defaultIndex = 0;
    const defaultBomb = this.bombTypeData.filter((bombData, i) => {
      if (bombData.name == '"Low-Yield Nuke"') {
        defaultIndex = i;
        return true;
      }
      if (bombData.name == 'Little Boy') {
        defaultIndex = i;
        return true;
      }
      return false;
    })[0];

    this.state = {
      injuries: 0,
      fatalities: 0,
      currentBombIndex: defaultIndex,
      locationName: '',
      latitude: 38.9072,
      longitude: -77.0369,
      bombKT: defaultBomb.kt,
      activeLabelId: null,
      showIntro: true,
      selectedEffect: null,
      isViewingEffect: false,
      isViewingCredits: false,
      airburst: false,
      loading: true,
      imagesLoaded: false,
      UIRevealed: false,
      explosionStatus: 'BEFORE',
      revealedEffects: {
        fireball: 'HIDDEN',
        radiation: 'HIDDEN',
        blast_psi5: 'HIDDEN',
        thermal: 'HIDDEN',
      },
      loadProgress: 0,
      mobileView: 'Default',
      mapLoaded: false,
      showPopup: false,
    };

    this.setRings(this.state.bombKT);
  }
  componentDidMount() {
    this.mounted = true;
    getCasualties = require('./utils/getCasualties').default;
    // Listen for browser back button
    this.unlisten = globalHistory.listen(({ location, action }) => {
      if (action === 'PUSH') {
        this.setStateFromRoute(location);
      }
    });

    this.setState({ loading: false });
    this.setStateFromRoute(this.props.location);

    this.preloadLayerImages().then(() => {
      this.setState({ imagesLoaded: true });
    });
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.location.search) {
      this.resetBlastState();
    }
  }
  componentWillUpdate(nextProps, nextState) {
    // check and update rings if necessary
    // moved this out of render for better performance
    if (
      this.state.currentBombIndex !== nextState.currentBombIndex ||
      this.state.bombKT !== nextState.bombKT ||
      this.state.longitude !== nextState.longitude ||
      this.state.latitude !== nextState.latitude ||
      this.state.airburst !== nextState.airburst
    ) {
      this.setRings(
        nextState.bombKT,
        nextState.airburst ? this.getBombHeight(nextState.currentBombIndex) : 0
      );
    }
  }
  componentWillUnmount() {
    this.mounted = false;
    this.unlisten();
  }
  setMobileViewEffects = () => {
    if (this.mounted) this.setState({ mobileView: 'Effects' });
  };
  setMobileViewEditing = () => {
    this.props.hideHeader();
    if (this.mounted) this.setState({ mobileView: 'Editing' });
  };
  setMobileViewDefault = () => {
    this.props.showHeader();
    if (this.mounted) this.setState({ mobileView: 'Default' });
  };
  setMapLoaded = () => {
    if (this.mounted) this.setState({ mapLoaded: true });
  };
  resetBlastState() {
    if (this.mounted) {
      this.setState({
        UIRevealed: false,
        explosionStatus: 'BEFORE',
        revealedEffects: {
          fireball: 'HIDDEN',
          radiation: 'HIDDEN',
          blast_psi5: 'HIDDEN',
          thermal: 'HIDDEN',
        },
        activeLabelId: null,
        selectedEffect: null,
        isViewingEffect: false,
        isViewingCredits: false,
      });
    }
    Object.keys(this.layers).map(k => this.layers[k].reset());
  }
  preloadLayerImages() {
    setTimeout(this.checkLoad, 500);
    return Promise.all(
      Object.keys(this.layers).map(k => this.layers[k].preloadImages())
    );
  }
  onImgLoaded = () => {
    this.numImagesLoaded += 1;
  };
  checkLoad = () => {
    const progress = this.numImagesLoaded / this.numImagesToLoad;
    if (this.mounted) this.setState({ loadProgress: progress });
    if (progress < 1) {
      setTimeout(this.checkLoad, 500);
    }
  };
  handleExplosionComplete = () => {
    if (this.mounted) {
      eventBus.dispatch('explosionComplete');
      this.setState({
        UIRevealed: true,
        explosionStatus: 'AFTER',
        revealedEffects: {
          fireball: 'DONE',
          radiation: 'DONE',
          blast_psi5: 'DONE',
          thermal: 'DONE',
        },
      });

      this.setState({ showPopup: true });
    }
  };
  handleExplosionStart = () => {
    if (this.mounted) this.setState({ explosionStatus: 'DURING' });
  };
  revealEffect = (activeLayer, deactiveLayer) => {
    const layerUpdate = {
      [activeLayer]: 'ACTIVE',
    };
    if (typeof deactiveLayer !== 'undefined') {
      layerUpdate[deactiveLayer] = 'INACTIVE';
    }
    if (this.mounted) {
      this.setState(
        { revealedEffects: { ...this.state.revealedEffects, ...layerUpdate } },
        () => {
          this.forceUpdate();
        }
      );
    }
  };
  setStateFromRoute(location) {
    if (!location.search) {
      if (this.mounted) this.setState({ showIntro: true });
      return;
    }
    const query = qs.parse(location.search);

    if (!query.lat || !query.long || !query.location) {
      if (this.mounted) this.setState({ showIntro: true });
      return;
    }

    if (this.mounted) {
      this.setState(
        {
          showIntro: false,
          currentBombIndex: parseInt(query.bomb ? query.bomb : '0', 10),
          latitude: parseFloat(query.lat),
          longitude: parseFloat(query.long),
          locationName: query.location,
          bombKT: this.bombTypeData[parseInt(query.bomb, 10)].kt,
          airburst: query.airburst === 'true',
        },
        () => {
          this.updateCasualties();
          this.setRings(
            this.state.bombKT,
            this.state.airburst
              ? this.getBombHeight(this.state.currentBombIndex)
              : 0
          );
        }
      );
    }
  }
  handleEffectClick = id => {
    if (this.mounted) {
      this.setState({
        selectedEffect: id,
        isViewingEffect: true,
        activeLabelId: null,
      });
    }
  };
  handleCloseEffect = () => {
    if (this.mounted) {
      this.setState({
        isViewingEffect: false,
        selectedEffect: null,
        activeLabelId: null,
      });
    }
    window.scrollTo(0, 0);
  };

  ringHover = label => {
    if (this.state.selectedEffect) {
      return;
    }
    if (typeof label !== 'undefined') {
      if (this.mounted) this.setState({ activeLabelId: label });
    } else {
      if (this.mounted) this.setState({ activeLabelId: null });
    }
  };
  ringClick = label => {
    if (typeof label !== 'undefined') {
      this.handleEffectClick(label);
    }
  };

  labelMouseEnter = id => {
    if (!this.state.selectedEffect) {
      if (this.mounted) this.setState({ activeLabelId: id });
    }
  };
  labelMouseLeave = () => {
    if (!this.state.selectedEffect) {
      if (this.mounted) this.setState({ activeLabelId: null });
    }
  };
  updateBomb = (index, kt) => {
    if (this.mounted) {
      this.setState(
        {
          bombKT: kt,
          currentBombIndex: index,
        },
        () => {
          this.updateCasualties();
          this.updateQuery();
        }
      );
    }
  };
  updateLocation = ({ name, long, lat }) => {
    if (this.mounted) {
      this.setState(
        {
          locationName: name,
          longitude: long,
          latitude: lat,
        },
        () => {
          this.updateCasualties();
          this.updateQuery();
        }
      );
    }
  };
  updateQuery() {
    const queryString = qs.stringify({
      lat: this.state.latitude,
      long: this.state.longitude,
      bomb: this.state.currentBombIndex,
      location: this.state.locationName,
      airburst: this.state.airburst,
    });

    navigate(`?${queryString}`)
  }

  setRings(bombKT, hob) {
    this.rings = explosionRings(bombKT, hob);
  }
  getBombHeight(index) {
    return this.bombTypeData[index].bombHeight;
  }
  getCurrentBombHeight() {
    return this.bombTypeData[this.state.currentBombIndex].bombHeight;
  }
  updateCasualties = () => {
    getCasualties(
      {
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        kt: this.state.bombKT,
        isAirburst: this.state.airburst,
        burstHeightFeet: this.state.airburst ? this.getCurrentBombHeight() : 0,
      },
      (fatalities, injuries) => {
        if (this.mounted) {
          this.setState({
            fatalities,
            injuries,
          });
        }
      }
    );
  };
  completeIntro = () => {
    this.setState({ showIntro: false });
  };

  toggleCredits = () => {
    this.setState({ isViewingCredits: !this.state.isViewingCredits });
  };
  toggleAirBurst = () => {
    this.setState({ airburst: true }, () => {
      this.updateCasualties();
      this.updateQuery();
    });
  };
  toggleSurfaceBurst = () => {
    this.setState({ airburst: false }, () => {
      this.updateCasualties();
      this.updateQuery();
    });
  };

  renderMap() {
    const {
      credit_body,
    } = this.props.data.interactive;
    return (
      <React.Fragment>
        <TransitionGroup>
          {this.state.isViewingEffect && (
            <Transition timeout={1500}>
              {state => (
                <EffectView
                  data={this.effectsListData}
                  selectedEffect={this.state.selectedEffect}
                  handleCloseEffect={this.handleCloseEffect}
                  ringSizes={this.rings}
                  hideHeader={this.props.hideHeader}
                  showHeader={this.props.showHeader}
                  transitionState={state}
                />
              )}
            </Transition>
          )}
        </TransitionGroup>

        {typeof window !== 'undefined' && (
          <Map
            handleExplosionStart={this.handleExplosionStart}
            bombKT={this.state.bombKT}
            rings={this.rings}
            latitude={this.state.latitude}
            longitude={this.state.longitude}
            hoveredLabelId={this.state.activeLabelId}
            selectedLabelId={this.state.selectedEffect}
            isViewingEffect={this.state.isViewingEffect}
            ringHover={this.ringHover}
            ringClick={this.ringClick}
            airburst={this.state.airburst}
            layers={this.layers}
            imagesLoaded={this.state.imagesLoaded}
            loadProgress={this.state.loadProgress}
            mobileView={this.state.mobileView}
            setMapLoaded={this.setMapLoaded}
            mapLoaded={this.state.mapLoaded}
            UIRevealed={this.state.UIRevealed}
          />
        )}
        <MapControls
          updateLocation={this.updateLocation}
          currentLocationName={this.state.locationName}
          bombTypes={this.bombTypeData}
          currentBombIndex={this.state.currentBombIndex}
          updateBomb={this.updateBomb}
          mboxClient={mboxClient}
          hidden={
            !this.state.UIRevealed ||
            this.state.isViewingEffect ||
            this.state.isViewingCredits
          }
          airburst={this.state.airburst}
          toggleAirBurst={this.toggleAirBurst}
          toggleSurfaceBurst={this.toggleSurfaceBurst}
          setMobileViewEffects={this.setMobileViewEffects}
          setMobileViewEditing={this.setMobileViewEditing}
          setMobileViewDefault={this.setMobileViewDefault}
          mobileView={this.state.mobileView}
        />

        <MapUIContainer
          hidden={this.state.isViewingEffect || this.state.isViewingCredits}
        >
          <CasualtiesAndShare
            injuries={this.state.injuries}
            fatalities={this.state.fatalities}
            explosionStatus={this.state.explosionStatus}
            UIRevealed={this.state.UIRevealed}
            toggleCredits={this.toggleCredits}
            mobileView={this.state.mobileView}
            setMobileViewEffects={this.setMobileViewEffects}
            setMobileViewEditing={this.setMobileViewEditing}
          />
          <EffectsList
            handleClick={this.handleEffectClick}
            effects={this.effectsListData}
            onMouseEnter={this.labelMouseEnter}
            onMouseLeave={this.labelMouseLeave}
            hoveredLabelId={this.state.activeLabelId}
            ringSizes={this.rings}
            revealedEffects={this.state.revealedEffects}
            UIRevealed={this.state.UIRevealed}
            mobileView={this.state.mobileView}
            setMobileViewDefault={this.setMobileViewDefault}
          />
        </MapUIContainer>

        <InteractiveCredits
          reveal={this.state.UIRevealed && this.state.isViewingCredits}
          hidden={this.state.isViewingEffect}
          toggleCredits={this.toggleCredits}
          content={credit_body}
          hideHeader={this.props.hideHeader}
          showHeader={this.props.showHeader}
          mobileBluecadet={false}
        />

        <span
          className={
            this.state.mapLoaded ? style.mapBottom : style.mapBottomHide
          }
        >
          <button
            onClick={this.toggleCredits}
            className={style.mobileInfoButton}
          >
            <span>{translations.t('About')}</span>
          </button>
          {/* <p className={style.desktopAboutCredit}>
            <a href="https://www.bluecadet.com" target="_blank" rel="noreferrer">
              {translations.t('Site by Bluecadet')}
            </a>
          </p>
          <p
            className={
              !this.state.UIRevealed ||
                this.state.isViewingEffect ||
                this.state.isViewingCredits
                ? style.takeActionHidden
                : style.takeAction
            }
          >
            <a href="/nuclear-weapons/articles/what-you-can-do-nuclear-weapons">
              {translations.t('Learn What You Can Do About Nukes')}
            </a>
          </p> */}
          <div className={style.mobileBottomBar} />
        </span>
      </React.Fragment>
    );
  }
  isSSR() {
    return typeof window === 'undefined';
  }
  render() {
    const {
      title,
      meta_title,
      meta_description,
      meta_image,
      featured_image,
      intro_description,
      video,
    } = this.props.data.interactive;

    const pTitle = this.props.data.interactive.callout_popup.title;
    const pBody = this.props.data.interactive.callout_popup.body;
    const pLink = this.props.data.interactive.callout_popup.link;

    return (
      <div id="nukemap" className={style.nukemap} style={this.props.style}>
        <HeadTags
          title={meta_title ? meta_title : title}
          description={meta_description}
          image={
            meta_image
              ? meta_image.sizes.hero_md
              : featured_image ? featured_image.image.sizes.hero_md : null
          }
          imageTwitter={
            meta_image
              ? meta_image.sizes.hero_sm
              : featured_image ? featured_image.image.sizes.hero_sm : null
          }
          location={this.props.location}
        />

        {this.state.loading && <LoadingScreen />}

        <TransitionGroup>
          {this.state.showIntro &&
            !this.state.loading && (
              <Transition timeout={1500}>
                {state => (
                  <NukemapIntro
                    introText={intro_description}
                    currentLocationName={this.state.locationName}
                    updateLocation={this.updateLocation}
                    handleSelect={this.completeIntro}
                    mboxClient={mboxClient}
                    video={video.url}
                    transitionState={state}
                    poster={featured_image.image.sizes.hero_sm}
                  />
                )}
              </Transition>
            )}
        </TransitionGroup>

        {!this.state.showIntro && this.renderMap()}

        {pTitle && pBody && pLink
          && <CallToActionPopup visible={this.state.showPopup && !this.state.isViewingCredits} data={this.props.data.interactive.callout_popup} />
        }
      </div>
    );
  }
}

export default BombBlast;
