import React from 'react';
import * as style from './burstType.module.scss';
import translations from '../../utils/translations';

const SurfaceText = props => (
  <p>
    {translations.t('Detonation happens at ground level. Bunkers below ground are impacted and radiation fallout is greater.')}
  </p>
);
const AirText = props => (
  <p>
    {translations.t('Detonation happens in the air. A larger geographic area is impacted by the blast.')}
  </p>
);

const BurstType = props => {
  return (
    <div className={style.burstTypeWrap}>
      <h3 className="f-label">{translations.t('Blast Type')}</h3>
      <div
        className={style.burstType}
        role="radiogroup"
        aria-label={translations.t('Surface or Air Burst')}
      >
        <div
          onClick={props.toggleSurfaceBurst}
          className={!props.airburst ? style.optionSelected : style.option}
          role="radio"
          aria-checked={!props.airburst}
          tabIndex="0"
        >
          {translations.t('Surface')}
          <div className={style.toolTip}>
            <SurfaceText {...props} />
          </div>
        </div>
        <div
          className={props.airburst ? style.optionSelected : style.option}
          onClick={props.toggleAirBurst}
          role="radio"
          aria-checked={props.airburst}
          tabIndex="0"
        >
          {translations.t('Air Burst')}
          <div className={style.toolTipRight}>
            <AirText {...props} />
          </div>
        </div>
      </div>
      <div className={style.mobileToolTip}>
        <div
          className={
            props.airburst ? style.mobileToolTipSelected : style.mobileToolTip
          }
        >
          <AirText {...props} />
        </div>
        <div
          className={
            !props.airburst ? style.mobileToolTipSelected : style.mobileToolTip
          }
        >
          <SurfaceText {...props} />
        </div>
      </div>
    </div>
  );
};

export default BurstType;
