import React from 'react';

const DownCaret = () => (
  <div className="downCaret">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.61 11.19"
      width="16px"
      height="8px"
    >
      <path
        d="M285.11,184.83l-9.32,8.75-.48.45-.48-.45-9.33-8.75"
        transform="translate(-264.5 -183.83)"
        style={{
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2px',
        }}
      />
    </svg>
  </div>
);

export default DownCaret;
