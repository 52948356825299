// extracted by mini-css-extract-plugin
export var burstTypeWrap = "burstType-module--burstTypeWrap--m2byE";
export var burstType = "burstType-module--burstType--+Jr4Z";
export var icon = "burstType-module--icon--Jz4ge";
export var option = "burstType-module--option--KspiW";
export var optionSelected = "burstType-module--optionSelected--P198Y burstType-module--option--KspiW";
export var toolTip = "burstType-module--toolTip--ecZ7+";
export var toolTipRight = "burstType-module--toolTipRight--O5QCR burstType-module--toolTip--ecZ7+";
export var mobileToolTip = "burstType-module--mobileToolTip--UiAPU";
export var mobileToolTipSelected = "burstType-module--mobileToolTipSelected---a2Be burstType-module--mobileToolTip--UiAPU";
export var slideUp = "burstType-module--slideUp--U4FM2";
export var slideUpLarge = "burstType-module--slideUpLarge--L4fgX";