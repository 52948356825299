import conversions from './conversions';
import NukeEffects from '../lib/nukeEffects';

// Get effects radii
// All units in miles

function getRadius(name, kt, hob = 0) {
  switch (name) {
    case 'fireball':
      return hob
        ? NukeEffects.fireball_radius(kt, true)
        : NukeEffects.fireball_radius(kt, false);
    case 'radiation':
      return hob
        ? NukeEffects.ground_range_from_slant_range(
            NukeEffects.initial_nuclear_radiation_distance(kt, 500),
            hob * conversions.ft2mi
          )
        : NukeEffects.initial_nuclear_radiation_distance(kt, 500);
    case 'blast_psi5':
      return NukeEffects.range_from_psi_hob(kt, 5, hob) * conversions.ft2mi;
    case 'blast_psi20':
      return NukeEffects.range_from_psi_hob(kt, 20, hob) * conversions.ft2mi;
    case 'thermal':
      return hob
        ? NukeEffects.ground_range_from_slant_range(
            NukeEffects.thermal_distance(kt, '_3rd-100', true),
            hob * conversions.ft2mi
          )
        : NukeEffects.thermal_distance(kt, '_3rd-100', false);
    default:
      throw new Error('invalid radius name');
  }
}

export default function explosionRings(kt, hob, casualties = false) {
  //if airburst / height of burst
  const ringNames = [
    'fireball',
    'radiation',
    'blast_psi5',
    // 'blast_psi20',
    'thermal',
  ];

  const maxR = getRadius('thermal', kt, hob);

  return ringNames
    .map(name => {
      const r = getRadius(name, kt, hob);
      const sqMiles = r * r * Math.PI;
      return {
        name,
        radiusMiles: r,
        areaSqMiles: sqMiles.toFixed(2),
        areaSqKm: (sqMiles * 2.58999).toFixed(2),
        ratio: r / maxR,
      };
    })
    .sort((a, b) => b.radiusMiles - a.radiusMiles);
}
