import React from 'react';
import basicMapDataToComponents from '../../templates/basicMapDataToComponents';
import EffectSize from './effectSize';
import LazyImage from '../ui/lazyImage';
import * as style from './effectContent.module.scss';
import VideoBackground from '../basic/videoBackground';

const EffectContent = props => {
  return (
    <div
      className={`${style.effectText} ${
        style[props.transitionState]
      } text-block--fill f-small`}
    >
      <div className={style.effectTextInner}>
        <div className={`typeFonts1 text-block page-block`}>
          <h1>{props.title}</h1>
          <EffectSize
            elClass={style.effectSize}
            ringSizes={props.ringSizes}
            id={props.id}
          />
        </div>
        <div className={style.mobileImgWrap}>
          {props.videoBackground ? (
            <div>
              <VideoBackground src={props.videoBackground} />
            </div>
          ) : (
            <div>
              {props.img && (
                <LazyImage
                  src={props.img.src}
                  alt={props.img.alt}
                  width={745}
                  height={505}
                  fitSize
                />
              )}
            </div>
          )}
        </div>
        {props.paragraphs && basicMapDataToComponents(props.paragraphs)}
      </div>
    </div>
  );
};

export default EffectContent;
