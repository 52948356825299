import React from 'react';

const XIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.41 19.41"
    width="13px"
    height="13px"
  >
    <g>
      <g>
        <line x1="18.71" y1="0.71" x2="0.71" y2="18.71" />
        <line x1="18.71" y1="18.71" x2="0.71" y2="0.71" />
      </g>
    </g>
  </svg>
);

export default XIcon;
