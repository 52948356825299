import React from 'react';
import * as style from './bombOptions.module.scss';
import translations from '../../utils/translations';

const BombTile = props => (
  <div
    className={props.isCurrent ? style.bombTileCurrent : style.bombTile}
    onClick={() => {
      props.updateBomb(props.index, props.kt);
      props.toggleActive();
    }}
  >
    <div className={style.tileInner}>
      <span className={style.yieldLabel}>{props.yieldLabel}</span>
      <img src={props.src} alt={translations.t("bomb")} />
      <h3>{props.name}</h3>
      <p>{props.description}</p>
    </div>
  </div>
);

const BombOptions = props => (
  <div className={props.active ? style.bombOptions : style.bombOptionsHidden}>
    {props.bombTypes.map((bomb, i) => (
      <BombTile
        {...bomb}
        t={props.t}
        isCurrent={props.currentBombIndex === i}
        index={i}
        toggleActive={props.toggleActive}
        updateBomb={props.updateBomb}
        key={`bomb${i}`}
      />
    ))}
  </div>
);

export default BombOptions;
