import { withPrefix } from 'gatsby-link';
import { loadImages } from '../../utils/loadImage';

export default class LayerManager {
  constructor({
    imgName,
    explosionDelay,
    ratioMultiplier,
    seqLength,
    onReveal,
    loadCallback,
    timeOffset,
    frequency,
    amplitude,
  }) {
    this.imgName = imgName;
    this.ratioMultiplier = ratioMultiplier;
    this.frame = 0;
    this.isActive = false;
    this.srcs = this.createSequence(seqLength);
    this.imgs = [];
    this.textureData = [];
    this.seqLength = seqLength;
    this.explosionDelay = explosionDelay;
    this.onReveal = onReveal;
    this.loadCallback = loadCallback;
    this.timeOffset = timeOffset;
    this.frequency = frequency;
    this.amplitude = amplitude;
    this.opacity = 1;
  }
  explode(withText, delay) {
    clearTimeout(this.explodeTimeout);
    this.frame = 0;
    this.isActive = false;
    let delayTime = withText ? this.explosionDelay : this.explosionDelay / 2;
    if (delay) {
      delayTime += delay;
    }
    this.explodeTimeout = setTimeout(() => {
      this.setActive(withText);
    }, delayTime);
  }
  setActive = withText => {
    this.isActive = true;
    if (withText) {
      this.onReveal();
    }
  };
  setInactive() {
    this.isActive = false;
  }
  reset() {
    this.frame = 0;
    this.isActive = false;
    clearTimeout(this.explodeTimeout);
  }
  preloadImages() {
    const srcsWithKeys = this.srcs.map((src, i) => ({ id: i, src: src }));
    return loadImages(srcsWithKeys, this.loadCallback).then(imgs => {
      this.imgs = imgs;
    });
  }
  setTextureData(data) {
    this.textureData = data;
  }
  getImageName() {
    return this.imgName;
  }
  getMultiplier() {
    return this.ratioMultiplier;
  }
  setSize(size) {
    this.width = size;
    this.height = size;
  }
  setPos(x, y) {
    this.posX = x;
    this.posY = y;
  }
  setOpacity(opacity) {
    this.opacity = opacity;
  }
  getDrawData() {
    return {
      textureInfoGetter: this.getNextImageTex,
      x: this.posX,
      y: this.posY,
      width: this.width,
      height: this.height,
      frequency: this.frequency,
      amplitude: this.amplitude,
      timeOffset: this.timeOffset,
      alpha: this.opacity,
    };
  }
  getNextImageTex = () => {
    if (this.isActive) {
      this.updateFrame();
      return this.textureData[this.frame];
    } else {
      return null;
    }
  };
  createSequence(length) {
    const seq = [];
    for (var i = 0; i <= length; i++) {
      seq.push(this.getImgURL(i));
    }
    return seq;
  }
  getImgURL(frame) {
    return withPrefix(`/blast-v2/${this.getImageName()}/img_${frame}.png`);
  }

  updateFrame() {
    if (this.frame < this.seqLength) {
      this.frame += 1;
    }
  }
}
