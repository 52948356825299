export default function getSquareCoords(center, radius) {
  var coords = {
    lat: center[1],
    long: center[0],
  };
  var km = radius;
  var distanceX = km / (111.32 * Math.cos(coords.lat * Math.PI / 180));
  var distanceY = km / 110.574;
  var longMin = coords.long - distanceX;
  var longMax = coords.long + distanceX;
  var latMin = coords.lat - distanceY;
  var latMax = coords.lat + distanceY;
  var square = [
    [longMin, latMin],
    [longMax, latMin],
    [longMax, latMax],
    [longMin, latMax],
  ];
  var boundingBox = [[longMin, latMin], [longMax, latMax]];
  return { blastSize: square, boundingBox };
}
