import React, { Component } from 'react';
import EffectContent from './effectContent';
import MapOverlay from './mapOverlay';

class EffectView extends Component {
  constructor() {
    super();
    this.state = {
      activeEffect: null,
    };
  }
  componentWillMount() {
    this.updateActiveEffect();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedEffect) {
      this.updateActiveEffect(nextProps);
    }
  }

  updateActiveEffect(props = this.props) {
    this.setState({
      activeEffect: props.data.filter(d => {
        return d.id === props.selectedEffect;
      })[0],
    });
  }
  render() {
    return (
      <MapOverlay
        img={{
          src: this.state.activeEffect.image,
          alt: this.state.activeEffect.imageAlt,
        }}
        videoBackground={this.state.activeEffect.videoBackground}
        layout="SIDE"
        hideHeader={this.props.hideHeader}
        showHeader={this.props.showHeader}
        handleClose={this.props.handleCloseEffect}
        transitionState={this.props.transitionState}
        buttonText="Back"
      >
        <EffectContent
          title={this.state.activeEffect.title}
          paragraphs={this.state.activeEffect.paragraphs}
          id={this.state.activeEffect.id}
          ringSizes={this.props.ringSizes}
          img={{
            src: this.state.activeEffect.image,
            alt: this.state.activeEffect.imageAlt,
          }}
          videoBackground={this.state.activeEffect.videoBackground}
          transitionState={this.props.transitionState}
        />
      </MapOverlay>
    );
  }
}

export default EffectView;
