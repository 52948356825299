import React, { Component } from 'react';
import translations from '../../utils/translations';
import ClickCatcher from '../ui/clickCatcher';
import DownCaret from '../ui/icons/downCaret';
import BombOptions from './bombOptions';
import * as style from './bombSettings.module.scss';
import SelectorInputDisplay from './selectorInputDisplay';

class BombSelector extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
    };
  }
  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };
  render() {
    const currentBomb = this.props.bombTypes[this.props.currentBombIndex];
    const { t } = this.props;
    return (
      <div className={`${style.bombSelectorWrap}`}>
        <SelectorInputDisplay
          isBomb={true}
          iconRight={DownCaret}
          iconRightClass="iconCaret"
          toggleActive={this.toggleActive}
          active={this.state.active}
          label={translations.t("Bomb")}
        >
          <span className={`${style.selectorText}`}>{currentBomb.name}</span>
        </SelectorInputDisplay>
        <BombOptions
          {...this.props}
          toggleActive={this.toggleActive}
          active={this.state.active}
        />
        {/* Catches click outside of open drop down. Uses React Portals :) */}
        {this.state.active && (
          <ClickCatcher
            handleClick={this.toggleActive}
            portalId="nukemap"
            noMobile
          />
        )}
      </div>
    );
  }
}

export default BombSelector;
