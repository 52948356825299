import React from 'react';
import * as settingStyle from "./bombSettings.module.scss";

const SelectorInputDisplay = props => {
  return (
    <div
      onClick={props.toggleActive}
      className={`${
        props.active ? settingStyle.selectorActive : settingStyle.selector
      }  ${props.isBomb ? settingStyle.selectorBomb : ''} ${
        props.color === 'clear' ? settingStyle.colorClear : ''
      } ${props.isIntro ? settingStyle.isIntro : ''}`}
    >
      {props.label && (
        <h3 className={`f-label ${settingStyle.inputLabel}`}>{props.label}</h3>
      )}
      <div className={settingStyle.selectorInner}>
        {props.children}

        <span
          className={`${settingStyle.iconRight} ${settingStyle.selectIcon}`}
        >
          <span
            className={settingStyle[props.iconRightClass]}
            onClick={() => {
              props.handleIconClick && props.handleIconClick();
            }}
          >
            <props.iconRight />
          </span>
        </span>
      </div>
    </div>
  );
};

export default SelectorInputDisplay;
