import React, { Component } from 'react';
import * as style from './progressBar.module.scss';

class ProgressBar extends Component {
  mounted = false;
  constructor() {
    super();
    this.state = {
      progress: 0,
    };
  }
  componentDidMount() {
    this.mounted = true;
    this.mountTime = Date.now();
  }
  // This is a bit :(,But doing it so progress bar tweens from 0 to
  // current progress on mount
  componentWillReceiveProps(nextProps) {
    const now = Date.now();
    if (
      this.props.progress !== nextProps.progress ||
      nextProps.progress === 1
    ) {
      if (!this.props.delay) {
        this.setState({ progress: nextProps.progress });
      } else {
        if (now - this.mountTime < this.props.delay) {
          if (nextProps.progress === 1) {
            setTimeout(() => {
              if (this.mounted) this.setState({ progress: nextProps.progress });
            }, now - this.mountTime);
          }
        } else {
          this.setState({ progress: nextProps.progress });
        }
      }
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  render() {
    return (
      <div className={style.bar}>
        <div className={style.background} />
        <div
          className={style.progress}
          style={{ transform: `scaleX(${this.state.progress})` }}
        />
      </div>
    );
  }
}

export default ProgressBar;
