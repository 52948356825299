import React, { Component } from 'react';
import { trackLocationUse } from '../../tracking';
import translations from '../../utils/translations';
import MagnifyingGlass from '../ui/icons/magnifyingGlass';
import XIcon from '../ui/icons/xIcon';
import * as settingStyle from "./bombSettings.module.scss";
import DetonateListener from './detonateListener';
import * as style from './locationSelector.module.scss';
import SelectorInputDisplay from './selectorInputDisplay';

class LocationSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      autocompleteValues: [],
      active: false,
      showAutocomplete: false,
      activeSuggestion: -1,
    };
    this.suggestions = [];
  }
  componentDidMount() {
    this.unsubscribe = DetonateListener.subscribe(this.chooseSuggestion);

    if (typeof this.props.currentLocationName !== 'undefined') {
      this.setState({ value: this.props.currentLocationName });
    }
    window.addEventListener('keydown', this.handleKey);
  }
  componentWillReceiveProps(nextProps) {
    if (
      typeof nextProps.currentLocationName !== 'undefined' &&
      nextProps.currentLocationName !== this.state.value
    ) {
      this.setState({ value: nextProps.currentLocationName });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKey);
    this.unsubscribe();
  }
  nextSuggestion() {
    this.isChoosing = true;
    if (
      this.state.activeSuggestion <
      this.state.autocompleteValues.length - 1
    ) {
      this.setState({ activeSuggestion: this.state.activeSuggestion + 1 });
    } else {
      this.setState({ activeSuggestion: 0 });
    }
  }
  prevSuggestion() {
    this.isChoosing = true;
    if (this.state.activeSuggestion > 0) {
      this.setState({ activeSuggestion: this.state.activeSuggestion - 1 });
    } else {
      this.setState({
        activeSuggestion: this.state.autocompleteValues.length - 1,
      });
    }
  }
  chooseSuggestion = () => {
    if (this.state.autocompleteValues.length > 0) {
      this.handleNewLocation(
        this.state.autocompleteValues[
          this.state.activeSuggestion > -1 ? this.state.activeSuggestion : 0
        ]
      );
    }

    this.isChoosing = false;

    // if (this.input && typeof this.input.focus !== 'undefined') {
    //   this.input.focus();
    // }
  };
  handleKey = e => {
    // blur on escape
    if (e.which === 27) this.input.blur();
    // dont do anything if not active
    if (!this.state.active || this.state.autocompleteValues.length === 0)
      return;

    switch (e.which) {
      case 9:
      case 13:
        // tab
        // return key
        if (this.state.active) {
          this.chooseSuggestion();
        }
        break;
      case 38:
        // up arrow
        e.preventDefault();
        this.prevSuggestion();
        break;
      case 40:
        // down arrow
        e.preventDefault();
        this.nextSuggestion();
        break;
      default:
    }
  };
  handleChange = e => {
    this.setState(
      {
        value: e.target.value,
        activeSuggestion: -1,
      },
      this.autofill
    );
  };
  autofill = () => {
    if (this.state.value.length === 0) {
      this.setState({
        autocompleteValues: [],
        showAutocomplete: false,
      });
      if (typeof this.props.onEmptyValue !== 'undefined') {
        this.props.onEmptyValue();
      }
      return;
    } else if (this.state.value.length >= 4) {
      this.props.mboxClient
        .geocodeForward(this.state.value, {
          autocomplete: true,
        })
        .then(res => {
          if (res.status === 200) {
            this.setState({
              autocompleteValues: res.entity.features,
              showAutocomplete: true,
            });
            if (typeof this.props.onAutofill !== 'undefined') {
              this.props.onAutofill();
            }
          }
        })
        .catch(err => {
          // handle errors
          console.error('mapbox geocode call error', err);
        });
    }
  };
  handleNewLocation = data => {
    trackLocationUse();
    this.setState({
      showAutocomplete: false,
      autocompleteValues: [],
      value: data.place_name,
      // active: false,
      activeSuggestion: -1,
    });

    // update parent map location
    this.props.updateLocation({
      name: data.place_name,
      long: data.center[0],
      lat: data.center[1],
    });

    if (typeof this.props.handleSelect !== 'undefined') {
      this.props.handleSelect();
    }
  };

  handleFocus = () => {
    this.setState({
      active: true,
      activeSuggestion: -1,
    });
    if (typeof this.props.handleFocus !== 'undefined') {
      this.props.handleFocus();
    }
  };
  handleBlur = () => {
    // if (this.isChoosing) return;
    this.setState({
      active: false,
      value: this.props.currentLocationName
        ? this.props.currentLocationName
        : this.state.value,
      autocompleteValues: [],
      showAutocomplete: false,
    });
    if (typeof this.props.handleBlur !== 'undefined') {
      this.props.handleBlur();
    }
  };
  clearValue = () => {
    this.setState({ value: '' });
  };
  renderSuggestions() {
    if (this.state.showAutocomplete) {
      const list = this.state.autocompleteValues.map((v, i) => (
        <li
          role="option"
          className={
            this.state.activeSuggestion === i ? style.activeSuggestion : ''
          }
          ref={el => {
            this.suggestions[i] = el;
          }}
          key={v.id}
          tabIndex="-1"
          onMouseDown={e => {
            this.handleNewLocation(v);
          }}
          id={`${this.props.id}option${i}`}
        >
          {v.place_name}
        </li>
      ));

      return (
        <ul className={`autocomplete-list`}>{list}</ul>
      );
    }
    return null;
  }
  shouldShowX() {
    return (
      (this.state.active && this.state.value) ||
      (!this.state.active && this.props.value)
    );
  }
  render() {
    return (
      <div
        className={`${settingStyle.locationSelectorWrap} ${
          this.props.isIntro ? style.isIntro : ''
        }`}
      >
        <SelectorInputDisplay
          isBomb={false}
          iconRight={this.state.value ? XIcon : MagnifyingGlass}
          iconRightClass={this.state.value ? 'iconX' : 'iconSearch'}
          handleIconClick={this.state.value ? this.clearValue : null}
          active={this.state.active}
          color={this.props.color}
          label={this.props.noLabel ? null : translations.t('Location')}
          isIntro={this.props.isIntro}
        >
          <input
            type="text"
            ref={el => {
              this.input = el;
            }}
            className={`${settingStyle.textInput}`}
            placeholder={translations.t("Enter a location")}
            value={this.state.value}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            spellCheck="false"
            autoComplete="off"
            aria-label={translations.t("Choose a location")}
            aria-expanded={this.state.showAutocomplete}
            aria-owns={`suggestions${this.props.id}`}
            aria-haspopup="listbox"
            aria-autocomplete="list"
            aria-controls={`suggestions${this.props.id}`}
            aria-activedescendant={
              this.state.activeSuggestion >= 0
                ? `${this.props.id}option${this.state.activeSuggestion}`
                : ''
            }
          />
        </SelectorInputDisplay>
        <div
          id={`suggestions${this.props.id}`}
          role="listbox"
          className={`${
            this.state.active ? style.suggestionsActive : style.suggestions
          }  ${this.props.inline ? 'inline' : ''}  ${
            this.props.color === 'clear' ? 'clear' : ''
          }`}
        >
          {this.renderSuggestions()}
        </div>
      </div>
    );
  }
}

export default LocationSelector;
